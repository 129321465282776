<script>
  import { startup_state, keywords_store, loading_keywords } from "$lib/store.js";
  import Keyword from "./Keyword.svelte";

  function clearKeywords() {
    keywords_store.update(() => {
      return [];
    });
    // keywords = $keywords_store;
    document.getElementById("search-input").focus();
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keywords" class="keywords">
  {#each $keywords_store as keyword (keyword)}
    <Keyword {keyword} showIcon={true} showChevron={true} selectableCategories={true} />
  {/each}
  {#if $loading_keywords}
    <div class="loader" />
  {/if}
  {#if $keywords_store.length > 0 && !$startup_state == true}
    <div class="keyword-unit">
      <span
        class="clear-keywords"
        role="button"
        tabindex="0"
        on:click={() => clearKeywords()}
        on:keypress={() => clearKeywords()} />
    </div>
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .keywords {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    padding-bottom: 22px;
    margin-left: 8px;
    margin-right: 8px;
    width: 90%;
    height: auto;
    /* transition: all 0.25s ease-in-out; */
    box-sizing: border-box;
    /* overflow: hidden; */
  }
  .keyword-unit {
    display: flex;
    gap: 3px;
    margin: 4px;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
  }
  .clear-keywords {
    background-image: url("/buttons/btn_clear_keywords.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .clear-keywords:hover {
    background-image: url("/buttons/btn_clear_keywords_hover.svg");
  }
  .loader {
    background-image: url("/icons/icon_bouncing-circles.svg");
    width: 50px;
    height: 52px;
    align-self: center;
    margin: 0 0 0 0;
  }

  @media (max-width: 480px) {
    .keywords {
      padding-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
    }
  }
</style>
