import { get } from "svelte/store";
import { keywords_store, recommended_titles, settings_store, selected_streamers, loading_titles } from "./store.js";
import { locale } from "svelte-i18n";
import { PUBLIC_API_BASE_URL } from "$env/static/public";
import { goto } from "$app/navigation";
import { locale_options } from "./LocaleConstants.js";

const url = PUBLIC_API_BASE_URL + "/api/search/searchTitles";

export const movieCount = 50;

export async function getTitles() {
  loading_titles.set(true);
  let all_titles = [];
  const titlesParams = JSON.stringify(getTitlesParams());
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: titlesParams
    });
    if (!response.ok) {
      throw new Error("Failed to fetch titles");
    }
    const response_json = await response.json();
    if (response_json.data.titles.length > 0) {
      all_titles = response_json.data.titles;
    } else {
      all_titles = -1; // as message to movieList, that search did not retrieve anything
    }
  } catch (err) {
    console.error(err.message);
    goto("/error");
  } finally {
    // writeParamsToURL(titlesParams);
    loading_titles.set(false);
    recommended_titles.update((arr) => {
      return [...arr, all_titles];
    });
  }
}

function getTitlesParams() {
  const settings = get(settings_store);
  return {
    tags: get(keywords_store),
    locale: locale_options[get(locale)],
    limit: movieCount,
    filters: {
      region: settings.region,
      audio: settings.audio_languages.map((lang) => lang.value),
      subtitles: settings.subtitle_languages.map((lang) => lang.value),
      streamers: get(selected_streamers).map((s) => s.id),
      minProdYear: settings.production_period[0],
      maxProdYear: settings.production_period[1]
    }
  };
}

export async function copySearch() {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set("search", encodeURIComponent(JSON.stringify(getTitlesParams())));
  console.log("copySearch");
  try {
    await navigator.clipboard.writeText(currentUrl);
  } catch (err) {
    console.error("Failed to copy to clipboard: ", err);
  }
}
