// Documentation link: https://kit.svelte.dev/docs/routing#page-page-js
// import { startup_state } from "$lib/store.js";
// import { get } from "svelte/store";

// console.log("page.js");
// all_streamers.set(await (await fetch("/cache_streamers.json")).json());
// console.log("$all_streamers: " + JSON.stringify(get(all_streamers)));
// if (new URL(window.location.href).searchParams.get("search")) {
//   startup_state.set(false);
// }
export const load = async ({ fetch }) => {
  try {
    const response = await fetch("/cache.json");
    if (!response.ok) {
      throw new Error(`Failed to load cache: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to load frontend cache", error);
    return {};
  }
};

// export let titleID;
// export let titleSearch;
// const currentUrl = new URL(window.location.href);
// titleID = currentUrl.searchParams.get("id") || false;
// const titleSearchParams = currentUrl.searchParams.get("search") || false;
// if (titleSearchParams) {
//   titleSearch = JSON.parse(decodeURIComponent(titleSearchParams));
// }
